import SiteWrapper from '@/components/siteWrapper';
import { useRouter } from 'next/router';

const NotFoundPage = () => {
  const router = useRouter();

  return (
    <SiteWrapper siteData={null}>
      <div className="flex h-[80vh] flex-col items-center justify-center text-center">
        <h1 className="mb-5 text-[7rem] font-black text-primary">404</h1>
        <h2 className="mb-3 text-[1.75rem] font-bold">Something went wrong</h2>
        <p className="text-gray-600">
          The page you are looking for was removed or might never existed.
        </p>

        <button onClick={() => router.back()} className="button large mt-10">
          Go back
        </button>
      </div>
    </SiteWrapper>
  );
};

export default NotFoundPage;
